import axios from 'axios';

export default {
  data() {
    return {
      exporting: false,
    }
  },
  computed: {
    totalExportRecords() {
      let total = 0;
      this.records.forEach(record => {
        total++;
        if (record.linkedForms.length) {
          record.linkedForms.forEach(() => {
            total++;
          });
        }
      });
      return total;
    }
  },
  methods: {
    async exportPdf() {
      if (!this.records.length) {
        this.$notify({
          group: 'alert',
          title: 'No records in list!',
          text: 'There are no records to create report.',
          type: 'error'
        });
        return;
      }
      try {
        this.exporting = true;
        const requests = [];
        const templateId = this.selectedTemplate?._id || null;
        const formId = this.formData._id;
        const records = this.records.map(r => r._id);
        const endpoint = $formsConfig.core.api.export();
        const fileNames = [];
        const options = {
          responseType: `arraybuffer`,
          headers: {
            'Accept': `application/pdf`,
          }
        };
        let isValid = true;
        // Check if export is good for a pdf format.
        if (templateId) {
          if (this.selectedTemplate.fields.length > 8) {
            isValid = false;
          } else {
            for (const lf of this.selectedTemplate.linkedForms) {
              if (lf.fields > 8) {
                isValid = false;
                break;
              }
            }
          }
        } else {
          if (this.formData.fields.length > 8) {
            isValid = false;
          } else {
            for (const lf of this.formData.linkedForms) {
              if (lf.fields > 8) {
                isValid = false;
                break;
              }
            }
          }
        }
        if (!isValid) {
          this.$notify({
            group: 'alert',
            title: 'Dimensions are too big for PDF format!',
            text:'Please, export to CSV instead.',
            type: 'warn'
          });
          this.exporting = false;
          return;
        }
        // Request the main form pdf.
        const landscape = templateId ?
          this.selectedTemplate.fields.length > 6 ? true : false :
          this.formData.fields.length > 6 ? true : false;
        requests.push(axios.post(endpoint, { landscape, records, templateId, formId }, options));
        fileNames.push(this.formData.title);
        // Request linked forms pdfs.
        for (const linkedForm of this.formData.linkedForms) {
          if (templateId) {
            // Check for the linked forms in the template, if empty (no fields),
            // no need to request a pdf.
            let empty = false;
            this.selectedTemplate.linkedForms.every(tlf => {
              if (tlf._id === linkedForm._id && !tlf.fields.length) {
                empty = true;
                return false;
              }
            });
            if (empty) {
              continue;
            }
          }
          const formId = linkedForm._id;
          const landscape = templateId ?
            this.selectedTemplate.linkedForms.find(lf => lf._id === formId).fields.length > 6 ? true : false :
            linkedForm.fields.length > 6 ? true : false;
          requests.push(axios.post(endpoint, { landscape, records, templateId, formId, isLinked: true }, options));
          fileNames.push(linkedForm.title);
        }
        const pdfResponses = await Promise.all(requests);
        if (pdfResponses.length && pdfResponses.length >= 2) {
          const JSZipModule = await import(`jszip`);
          const JSZipUtilsModule = await import(`jszip-utils`);
          const JSZip = JSZipModule.default;
          const JSZipUtils = JSZipUtilsModule.default;
          const zip = new JSZip();
          let filesAdded = 0;
          for (const [i, response] of pdfResponses.entries()) {
            const data = response.data;
            const objUrl = URL.createObjectURL(
              new Blob([data], { type: `application/pdf`,  encoding: `raw` }),
            );
            JSZipUtils.getBinaryContent(objUrl, (err, data) => {
              zip.file(`${fileNames[i]}.pdf`, data, { binary: true });
              filesAdded++;
              if (filesAdded == pdfResponses.length) {
                zip.generateAsync({ type: `blob` }).then(blob => {
                  saveAs(blob, this.formData.title);
                });
              }
            });
          }
        } else {
          const blob = new Blob([pdfResponses[0].data], { type: `application/pdf`,  encoding: `raw` });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `${this.formData.title}.pdf`;
          link.click();
        }
        if (this.onCancel) {
          this.onCancel(true);
        }
      } catch (error) {
        this.$notify({
          group: 'alert',
          title: 'Something went wrong!',
          text:
            (error && error.message) ||
            'We were unable to retrive data.',
          type: 'error'
        });
        console.error(error);
      } finally {
        this.exporting = false;
      }
    },
    async generateCsv({ formId }) {
      const processRow = row => {
        let finalVal = '';
        for (let j = 0; j < row.length; j++) {
          let innerValue = row[j] === null ? '' : row[j].toString();
          if (row[j] instanceof Date) {
            innerValue = row[j].toLocaleString();
          }
          const result = innerValue.replace(/"/g, '""');
          if (result.search(/("|,|\n)/g) >= 0) {
            result = '"' + result + '"';
          }
          if (j > 0) {
            finalVal += ',';
          }
          finalVal += result;
        }
        return finalVal + '\n';
      };
      const isHidden = field => {
        let hideFromRecords = false;
        if (!field || !field.configs) {
          return hideFromRecords;
        }
        field.configs.forEach(config => {
          if (config.key === 'hideFromRecords') {
            hideFromRecords = config.value;
            return;
          }
        });
        return hideFromRecords;
      };
      try {
        const response = await axios.get($formsConfig.core.api.forms);
        const forms = response.data;
        const currentForm = forms.find(form => form._id === formId);
        const isLinkedForm = this.formData._id !== formId;
        const rows = [[]];
        for (const [i, record] of this.records.entries()) {
          const processFields = ({ template, fields, records, subrecords }) => {
            for (const field of fields) {
              if (field.keyName === 'FormField') {
                const nestedForm = forms.find(form => form._id === field.value);
                const records = subrecords.filter(sub => sub.form._id === nestedForm._id);
                // Apply multiple subrecords.
                records.forEach(r => {
                  for (const f of nestedForm.fields) {
                    if (isHidden(f)) {
                      continue;
                    }
                    // Check if field is present in the template. Continue if it is not. No needed.
                    const templateFieldFound = template ? template.fields.find(tf => tf.fieldId === f.id) : null;
                    if (template && !templateFieldFound) {
                      continue;
                    }
                    let value = r.form.fields.find(ff => ff.id === f.id).value || ` `;
                    if (!(value instanceof Array) && value instanceof Object) {
                      value = value.name;
                    }
                    const name = templateFieldFound ? templateFieldFound.alias || f.label : f.label;
                    // Populate csv headers using first loop.
                    if (i === 0) {
                      rows[i].push(name);
                    }
                    rows[i + 1].push(value);
                  }
                });
                continue;
              }
              if (isHidden(field)) {
                continue;
              }
              // Check if field is present in the template. Just return if it is not. No field needed then.
              const templateFieldFound = template ? template.fields.find(f => f.fieldId === field.id) : null;
              if (template && !templateFieldFound) {
                continue;
              }
              let value = records.find(r => r.id === field.id).value || ` `;
              if (!(value instanceof Array) && value instanceof Object) {
                value = value.name;
              }
              const name = templateFieldFound ? templateFieldFound.alias || field.label : field.label;
              // Populate csv headers using first loop.
              if (i === 0) {
                rows[i].push(name);
              }
              rows[i + 1].push(value);
            }
          };
          // Add a new row array per record.
          rows.push([]);
          if (isLinkedForm) {
            for (const linkedForm of record.linkedForms) {
              if (linkedForm._id === currentForm._id) {
                const fields = currentForm.fields;
                const records = linkedForm.fields;
                const subrecords = linkedForm.subrecords;
                const template = this.selectedTemplate ?
                this.selectedTemplate.linkedForms.find(lf => lf._id === linkedForm._id) :
                null;
                processFields({ fields, records, subrecords, template });
              }
            }
          } else {
            // Add prefix Id to the main form fields.
            const fields = [{
                id: 'prefixIdField',
                label: 'Prefix ID',
                value: currentForm.prefixId,
              },
              ...currentForm.fields];
            const records = record.fields;
            const subrecords = record.subrecords;
            const template = this.selectedTemplate;
            processFields({ fields, records, subrecords, template });
          }
        }
        let csvFile = ``;
        rows.forEach(row => {
          csvFile += processRow(row);
        });
        return csvFile;
      } catch (error) {
        this.$notify({
          group: 'alert',
          title: 'Something went wrong!',
          text:
            (error && error.message) ||
            'We were unable to retrive data.',
          type: 'error'
        });
        console.error(error);
      }
    },
    exportToCsv() {
      if (!this.records.length) {
        this.$notify({
          group: 'alert',
          title: 'No records in list!',
          text: 'There are no records to create report.',
          type: 'error'
        });
        return;
      }
      this.exporting = true;
      import('jszip').then(module => {
        const JSZip = module.default;
        import('jszip-utils').then(async module => {
          const JSZipUtils = module.default;
          const zip = new JSZip();
          const templateId = this.selectedTemplate?._id || null;
          // Request linked forms csv.
          for (const linkedForm of this.formData.linkedForms) {
            if (templateId) {
              // Check for the linked forms in the template, if empty (no fields),
              // no need to request a csvz.
              let empty = false;
              this.selectedTemplate.linkedForms.every(tlf => {
                if (tlf._id === linkedForm._id && !tlf.fields.length) {
                  empty = true;
                  return false;
                }
              });
              if (empty) {
                continue;
              }
            }
            const linkedFormId = linkedForm._id;
            const linkedFormName = linkedForm.title;
            const csvFile = await this.generateCsv({ formId: linkedFormId });
            const objUrl = URL.createObjectURL(
              new Blob([csvFile], { type: `text/csv;charset=utf-8;` }),
            );
            JSZipUtils.getBinaryContent(objUrl, (err, data) => {
              zip.file(`${linkedFormName}.csv`, data, { binary: true });
            });
          }
          // Request the main form csv.
          const formId = this.formData._id;
          const mainCsvFile = await this.generateCsv({ formId });
          if (!Object.keys(zip.files).length) {
            const blob = new Blob([mainCsvFile], { type: `text/csv;charset=utf-8;` });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${this.formData.title}.csv`;
            link.click();
            this.exporting = false;
            if (this.onCancel) {
              this.onCancel(true);
            }
            return;
          }
          const objUrl = URL.createObjectURL(
            new Blob([mainCsvFile], { type: `text/csv;charset=utf-8;` })
          );
          JSZipUtils.getBinaryContent(objUrl, (err, data) => {
            zip.file(`${this.formData.title}.csv`, data, { binary: true });
            zip.generateAsync({ type: 'blob' }).then(blob => {
              saveAs(blob, this.formData.title);
            })
            .finally(() => {
              this.exporting = false;
              if (this.onCancel) {
                this.onCancel(true);
              }
            });
          });
        });
      });
    },
  }
};
